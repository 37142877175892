@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.cdnfonts.com/css/komika-hand");

body {
  background-color: #492b33;
  font-family: "Komika Hand", sans-serif;
  color: #404040;
  background-image: url(./assets/bgbgbg.webp);
  background-size: contain;
  background-position: top;
}

.bgimg {
  background-image: url(./assets/bgimg.png);
  background-size: cover;
  background-position: center;
}

.bgjoin {
  background-image: url(./assets/bgjoin.png);
  background-size: cover;
  background-position: center;
}

.hello {
  outline-style: dashed;
  outline-width: 2px;
  outline-color: white;
  outline-offset: 3px;
  border-radius: 30px;
}

@media (min-width: 768px) {
  .hello {
    outline-width: 7px;
    outline-offset: 5px;
    border-radius: 60px;
  }
}
